<template>
  <div
    style="height: 100%;"
  >
    <AppLoaderScreen
      v-if="!menu_is_requested && !is_select_auth_form"
      :theme="theme"
      @on410="navigateToRegForm"
    />
    <MarketLoaderScreen
      v-else-if="isMarket && isMarketLoaderVisible"
      @hideMarketLoader="isMarketLoaderVisible = false"
    />
    <MerchLoaderScreen
      v-else-if="isMerch && isMerchLoaderVisible"
      @hideMarketLoader="isMerchLoaderVisible = false"
    />
    <AppLayout
      v-else
      :hide-navs="is_select_auth_form && !menu_is_requested"
    >
      <template
        v-slot:header
      >
        <RouterView
          name="header"
        />
      </template>
      <template v-slot:main>
        <RouterView />
        <Transition appear name="pop">
          <AppSafetyNotice
            v-if="showSafetyNotice"
            @close="closeSafetyNotice"
          />
        </Transition>
      </template>
      <template
        v-slot:footer
      >
        <RouterView
          name="footer"
        />
        <FloatingNav
          v-if="showFloatingNav"
        />
      </template>
    </AppLayout>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AppLayout from "@/components/App/AppLayout";
import AppLoaderScreen from "@/components/App/AppLoaderScreen.vue";
import FloatingNav from "@/components/FloatingNav";
import MarketLoaderScreen from "@/components/marketplace/market_loader.vue";
import MerchLoaderScreen from "@/components/merch/merch_loader.vue";
import app from '@/helpers/app';
import baseApi from "@/helpers/baseApi";
import { setTabActiveHandler, pwa } from '@/helpers/bridge';
import { MAIN_PAGE } from "@/config/constants";
import { detectedBridgePlatform } from "@/helpers/bridge";
import { authorization } from '@/services/auth';
import AppSafetyNotice from "./components/App/AppSafetyNotice.vue";

export default {
  name: "APP",
  components: {
    FloatingNav,
    AppLayout,
    AppLoaderScreen,
    MarketLoaderScreen,
    MerchLoaderScreen,
    AppSafetyNotice,
  },
  data(){
    return {
      header: {
        title: 'Smart ERG',
        back: false,
      },
      showFloatingNav: process.env.VUE_APP_SHOW_FLOATING_MENU === 'true',
      theme: new URLSearchParams(window.location.search).get('theme'),
      isMarketLoaderVisible: true,
      isMerchLoaderVisible: true,
      showSafetyNotice: false,
    };
  },
  async created() {
    if (detectedBridgePlatform) {
      pwa();
      this.$store.dispatch('notifications/setEnabled');
    }
    await this.initialize();
    this.checkSafetyNotice();
  },
  methods: {
    checkSafetyNotice() {
      const today = new Date().toISOString().split("T")[0];
      const lastShownDate = localStorage.getItem("lastShownDate");

      if (lastShownDate !== today) {
        this.showSafetyNotice = true;
         localStorage.setItem("lastShownDate", today);
      } else {
        this.showSafetyNotice = false;
      }
    },
    closeSafetyNotice() {
      this.showSafetyNotice = false;
    },
    tabActiveHandler(){
      if(this.$route.name !== MAIN_PAGE){
        this.$router.replace({
          name: 'MainPage',
        });
      }
    },
    async initialize(){
      try {
        await authorization();
        this.$store.dispatch('mainPage/initialize');
      } catch (err) {
        throw Error(err);
      }
    },
    navigateToRegForm(payload){ // on 410
      this.$store.dispatch('layout/setIsSelectAuthForm', true);
      this.$router.replace({
        name: 'FormPage',
        params: {
          form_data: payload,
        },
      });
    },

    headerMenuItemClickHandler(id){
      let { dispatch } = this.$store;
      if (id === 'reload'){
        location.reload();
      } else {
        dispatch('layout/alert', 'Неизвестный эллемент меню: ' + id);
      }
    },
  },
  computed: {
    ...mapGetters({
      menu_is_requested: 'mainPage/menu_is_requested',
    }),
    ...mapState("layout", [
      "language",
      "is_select_auth_form",
      "header_title",
    ]),
    show_header_menu_items(){
      return !this.is_select_auth_form && this.menu_is_requested;
    },
    isMarket() {
      return this.$route.path.startsWith('/s/market');
    },
    isMerch() {
      return this.$route.path.startsWith('/s/merch');
    },
  },

  watch: {

    "$store.state.layout.theme_color": (newColor, oldColor) => {
      if (newColor !== oldColor){
        document.querySelector('meta[name="theme-color"]').setAttribute('content',  newColor);
      }
    },

    menu_is_requested(val){
      if (val){
        app.backNavigateQueue.init();
        setTabActiveHandler(this.tabActiveHandler.bind(this));
      }
    },

    show_header_menu_items(val){
      if (val){
        app.initHeaderMenuItems({
          handler: this.headerMenuItemClickHandler,
        });
      }
    },
    language: {
      immediate: true,
      handler(val){
        baseApi.defaults.headers['Accept-Language'] = val;
      },
    },
  },
};
</script>
<style lang="scss">
@use "normalize.css/normalize";
@use "@/styles/index.scss";

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
}
</style>