<template>
  <div
    class="notice__overlay"
    @click="close(false)"
  >
    <div
      class="notice__card"
      @click.stop
    >
      <div
        class="notice__card__top"
      >
        <div
          v-if="loading"
          class="notice__card__top-loading"
        >
          <VProgressCircular
            color="#EF7F1A"
            indeterminate
          />
        </div>
        <img
          v-if="banners[currentBanner].image"
          :key="currentBanner"
          class="card__top__image"
          :src="banners[currentBanner].image.src"
          @load="handleImageLoad"
        >
      </div>
      <div class="notice__card__body">
        <div
          class="card__body__btn"
          @click="close(false)"
        >
          {{ $translate('agree_safety_precautions') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSafetyNotice',
  data(){
    return {
      currentBanner: 0,
      loading: true,
      banners: [
        {
          image: {
            src: require('@/assets/safety-notice/safety-notice-1.jpg'),
          },
        },
        {
          image: {
            src: require('@/assets/safety-notice/safety-notice-2.jpg'),
          },
        },
        {
          image: {
            src: require('@/assets/safety-notice/safety-notice-3.jpg'),
          },
        },
        {
          image: {
            src: require('@/assets/safety-notice/safety-notice-4.jpg'),
          },
        },
        {
          image: {
            src: require('@/assets/safety-notice/safety-notice-5.jpg'),
          },
        },
      ],
    };
  },

  mounted(){
    this.$root.$el.style.overflow = 'hidden';

    let lastShownSlide = localStorage.getItem('lastShownSlide');
    console.log('lastShownSlide', lastShownSlide);
    if (lastShownSlide < 4) {
      this.currentBanner = ++lastShownSlide;
      localStorage.setItem('lastShownSlide', this.currentBanner);
    } else {
      this.currentBanner = 0;
      localStorage.setItem('lastShownSlide', this.currentBanner);
    }
  },

  beforeDestroy(){
    this.$root.$el.style.overflow = null;
  },
  methods: {
    close(){
      this.$emit('close');
    },
    handleImageLoad() {
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.notice__overlay{
  position: fixed;
  overflow-y: auto;
  background-color: rgba(21, 28, 34, 0.4);
  top:0;
  left: 0;
  right:0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .notice__card{
    background: #FFFFFF;
    margin: 35px;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    .notice__card__top{
      &-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      }
      &--align-top{
        align-items: flex-start;
      }
      .card__top__image{
        width: 100%;
        height: 100%;
      }
    }
    .notice__card__body{
      padding: 24px;
      .card__body__btn{
        background: #EF7F1A;
        border-radius: 12px;
        text-align: center;
        color: #FFFFFF;
        padding: 8px;
        user-select: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
      }
    }
  }
}
</style>